import React from 'react';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import Layout, { LAYOUT_TYPES } from '../modules/layout';
import { ResourceSetProvider } from '../context/ResourceSetContext';
import { ThemeProvider } from 'styled-components';
import { theme, ShowAt } from '@sygnia/components';
import Main from '../modules/main';
import { mapper } from '../common/utils';

const SD_MAP = {
  title: ['headline'],
  image: ['image'],
  altText: ['structuredData', 'altText'],
  description: ['structuredData', 'description', 'description'],
};

const getLayout = (landingPage, isMobile, url) => {
  const formProps = landingPage.callToAction
    ? JSON.parse(landingPage.callToAction?.component?.internal?.content)
    : null;
  const sd = mapper(landingPage, SD_MAP);
  let layout = [
    {
      __typename: LAYOUT_TYPES.HELMET,
      structuredData: sd,
    },
    {
      __typename: LAYOUT_TYPES.HERO_LANDING,
      headline: landingPage.headline,
      eyeBrow: landingPage.subtitle,
      description: landingPage.description,
      sdDescription: sd.description,
      color: landingPage.color?.value,
      secondaryColor: landingPage.secondaryColor?.value,
      small: landingPage.small,
      medium: landingPage.medium,
      large: landingPage.large,
      xlarge: landingPage.xlarge,
      image: landingPage.image,
      url: url,
      heroLinks: landingPage.heroLinks,
    },
    {
      __typename: LAYOUT_TYPES.CONTENT_BODY,
      isRich: true,
      document: landingPage.content.document,
      contentGrid: {
        mb: [0, 0, 10],
        width: [1, 1, 6.9 / 12],
        order: [1, 1, 0],
      },
      sidebarGrid: {
        width: [1, 1, 4.7 / 12],
        order: [0, 0, 1],
        isSticky: !isMobile,
      },
      sidebar: [
        {
          __typename: LAYOUT_TYPES.LANDING_PAGE_WIDGET,
          section: { ...formProps },
          id: landingPage.contentful_id,
          title: landingPage.callToAction.name,
        },
      ],
    },

    isMobile && {
      __typename: LAYOUT_TYPES.LANDING_PAGE_WIDGET,
      section: { ...formProps },
      id: landingPage.contentful_id,
      title: landingPage.callToAction.name,
      styles: { mx: [2] },
    },
    {
      __typename: LAYOUT_TYPES.SHARE_LANDING,
      description: landingPage.description,
      title: landingPage.headline,
      image: landingPage.image,
    },
    // {
    //   __typename: LAYOUT_TYPES.CTA_CENTERED,
    //   footnote: landingPage.footer.footnote,
    //   title: landingPage.footer.title,
    //   description: landingPage.footer.description,
    //   image: landingPage.footer.image,
    // },
  ];

  return layout;
};

const LandingPageTemplate = props => {
  const url = props.location.href;
  const landingPage = get(props, 'data.contentfulLandingPage');
  return (
    <ThemeProvider theme={theme}>
      <ResourceSetProvider>
        <Main {...props} page={landingPage}>
          {/* Needed to fix offset for anchor link */}
          <a
            name="anchor"
            id={landingPage.contentful_id}
            style={{ position: 'absolute', top: '800px' }}
          />
          <ShowAt breakpoint="mediumAndBelow">
            <Layout layout={getLayout(landingPage, true, url)} />
          </ShowAt>
          <ShowAt breakpoint="large">
            <Layout layout={getLayout(landingPage, false, url)} />
          </ShowAt>
        </Main>
      </ResourceSetProvider>
    </ThemeProvider>
  );
};

export default LandingPageTemplate;

export const pageQuery = graphql`
  query LandingPageBySlug($slug: String!) {
    contentfulLandingPage(slug: { eq: $slug }) {
      contentful_id
      content {
        document: json
      }
      description {
        childMarkdownRemark {
          html
        }
      }
      heroLinks {
        ...LinkFields
      }
      subtitle
      headline
      slug
      footer {
        description {
          childMarkdownRemark {
            html
          }
        }
        title
        footnote {
          childMarkdownRemark {
            html
          }
        }
        image {
          fluid {
            src
          }
        }
      }
      structuredData {
        ...StructuredDataFields
      }
      image {
        fluid {
          src
        }
      }
      xlarge: image {
        fluid(quality: 100, maxWidth: 1200) {
          src
        }
      }
      large: image {
        fluid(quality: 100, maxWidth: 992) {
          src
        }
      }
      medium: image {
        fluid(quality: 100, maxWidth: 768) {
          src
        }
      }
      small: image {
        fluid(quality: 100, maxWidth: 576) {
          src
        }
      }
      color {
        value
      }
      secondaryColor {
        value
      }
      callToAction {
        name
        component {
          internal {
            content
          }
        }
      }
    }
  }
`;
